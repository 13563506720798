<template>
  <div>
    <div class="mb-7">
      <h2 class="font-weight-bold black--text">
        E-Learning Ridho Mckinnon
      </h2>
    </div>
    <v-row>
      <v-col
        v-for="subject in subjects"
        :key="subject.title"
        md="4"
        sm="12"
        xs="12"
      >
        <v-card>
          <v-card
            height="150"
            color="#D14362"
          >
            <div class="d-flex justify-center">
              <div>
                <v-img
                  :src="subject.thumbnail"
                  width="100%"
                ></v-img>
              </div>
            </div>
          </v-card>
          <v-card-text
            class="py-4"
          >
            <div class="mb-4">
              <h2 class="font-weight-bold mb-4 black--text">
                {{ subject.title }}
              </h2>
              <div
                v-for="detail in subject.detail"
                :key="detail.title"
                class="d-flex mb-2"
              >
                <div class="d-flex align-center mb-2">
                  <v-img
                    :src="detail.icon"
                    width="16"
                  ></v-img>
                  <span class="ml-4">{{ detail.title }}</span>
                </div>
              </div>
            </div>
          </v-card-text>
          <v-card-actions class="pa-0">
            <router-link
              class="v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--x-large primary"
              to="/elearning-parent/subject/section"
              style="width: 100%;"
            >
              Lihat E-Learning
            </router-link>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import thumbnailSubject from '../../../assets/images/vector/subject.svg'
import iconUser from '../../../assets/icons/user.svg'
import iconClock from '../../../assets/icons/clock.svg'
import iconClass from '../../../assets/icons/class.svg'

export default {
  data() {
    return {
      subjects: [
        {
          thumbnail: thumbnailSubject,
          title: 'Matematika',
          detail: [
            {
              icon: iconUser,
              title: 'Ridho Mckinnon',
            },
            {
              icon: iconClock,
              title: '12:09, 18/01/2022',
            },
            {
              icon: iconClass,
              title: 'Kelas VI',
            },
          ],
        },
        {
          thumbnail: thumbnailSubject,
          title: 'Bahasa Indonesia',
          detail: [
            {
              icon: iconUser,
              title: 'Ridho Mckinnon',
            },
            {
              icon: iconClock,
              title: '14:19, 18/01/2022',
            },
            {
              icon: iconClass,
              title: 'Kelas V',
            },
          ],
        },
        {
          thumbnail: thumbnailSubject,
          title: 'Bahasa Inggris',
          detail: [
            {
              icon: iconUser,
              title: 'Ridho Mckinnon',
            },
            {
              icon: iconClock,
              title: '14:44, 18/01/2022',
            },
            {
              icon: iconClass,
              title: 'Kelas IV',
            },
          ],
        },
      ],
    }
  },
}
</script>

<style>
</style>
